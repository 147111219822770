import React, { useState } from 'react';
import { graphql } from 'gatsby';
import CafeList from '../components/CafeList';
import Layout from '../layouts/default';

const CafesPage = ({ data, searchQuery }) => (
  <Layout>
    <CafeList
      cafes={data.takeshape.cafes}
      className="cafePage"
      searchQuery={searchQuery}
    />
  </Layout>
);

export default CafesPage;

export const query = graphql`
  query {
    takeshape {
      cafes: getCafeList(
        sort: { order: "DESC", field: "_enabledAt" }
        size: 250
      ) {
        items {
          name
          cafeUrl
          cardBio
          coverImage {
            path
          }
          location
          shortLocation
          shortName
          status: statusHtml
          howToHelp: howToHelpHtml
          sellingCoffee
          searchTerms
        }
      }
    }
  }
`;
